import { Box, Button, Theme, Typography } from "@mui/material";
import React,{lazy, Suspense} from "react";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
const ColorChooser = lazy(()=>import("./ColorChooser"));
const useStyles = (theme: Theme) =>
    createStyles({
        root: {},
        paintButton: {
            width: "100%",
            marginTop: theme.spacing(2),
        },
    });

export type orientation = {
  tiltLR: number | null;
  tiltFB: number | null;
  dir: number | null;
};

interface Props extends WithStyles<typeof useStyles> {
  active: boolean;
  onPaintUpdate: (paint: boolean, color: string) => void;
  onOrientationUpdate: (deviceOrientation: orientation) => void;
}

class Paint extends React.Component<Props> {
    state = {
        color: "00ff00",
        noDeviceOrientation: false,
        painting: false,
    };

    constructor(p: Props) {
        super(p);
        this.addListeners();
    }

    handleChangeComplete = (color: string) => {
        this.setState({ color });
        this.sendPaintUpdate(this.state.painting);
    };

    onPress = () => {
        this.setState({ painting: true });
        this.sendPaintUpdate(true);
    };


    onRelease = () => {
        this.setState({ painting: false });
        this.sendPaintUpdate(false);
    };

    sendPaintUpdate(painting: boolean) {
        const { color } = this.state;
        this.props.onPaintUpdate(painting, color);
    }

    onDeviceOrientation = (e: DeviceOrientationEvent) => {
        const tiltLR = e.gamma;
        const tiltFB = e.beta;
        const dir = e.alpha;
        this.props.onOrientationUpdate({ tiltLR, tiltFB, dir });
    };


    addListeners = () => {
        if (this.props.active && !this.state.noDeviceOrientation) {
            if (
                typeof DeviceMotionEvent != "undefined" &&
            typeof(DeviceMotionEvent as any).requestPermission == "function"
            ) {
                (DeviceMotionEvent as any).requestPermission().then((response: "granted"|any) => {
                    if (response === "granted") {
                        window.addEventListener(
                            "deviceorientation",
                            this.onDeviceOrientation
                        );
                    } else {
                        this.setState({ noDeviceOrientation: true });
                    }
                });
            } else {
                window.addEventListener("deviceorientation", this.onDeviceOrientation);
            }
        }
    };


    render() {
        const { classes } = this.props;
        return (
            <Box>
        
                <Typography sx={{marginBottom:"16px"}}>Use your phone as a paint brush, point the top of the phone at the display and hold the paint button down to paint with light!</Typography>

                <Suspense fallback={(<Box>Loading</Box>)}><ColorChooser
                    onSelect={this.handleChangeComplete}
                    color={"#" + this.state.color}
                />
                </Suspense>
                <Button
                    variant="contained"
                    className={classes.paintButton}
                    style={{ padding: "10px" }}
                    onTouchStartCapture={this.onPress}
                    onTouchEnd={this.onRelease}
                >
          Hold to Paint
                </Button>
            </Box>
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(Paint);
