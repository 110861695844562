import {
    Box,
    Button,
    Link,
    Theme,
} from "@mui/material";
import React from "react";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import LogoBox from "../prims/LogoBox";
import { useNavigate } from "react-router-dom";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {},
        fullWidthButton: {
            marginTop: theme.spacing(0),
            width: "100%",
        },
    });
};

interface Props extends WithStyles<typeof useStyles> { }

function Thanks (props:Props) {
    const { classes } = props;
    const n = useNavigate();
    gtag("event", "thanks", {
        "event_category": "static",
        "event_label": "Thanks page"
    });

    return (
        <LogoBox>
            <Box>
                <h2>Thanks for playing with the lights!</h2>
                <p>

                        If you had a good time share with your friends!</p><p>
                    <FacebookShareButton quote="I just played with some interactive Christmas Lights!  You should too!" hashtag="#playwithlights" url="https://playwithlights.com">
                        <Box m={1}><FacebookIcon size={32} round={true} /></Box>
                    </FacebookShareButton>
                    <TwitterShareButton title="I just played with some interactive Christmas Lights!  You should too!" hashtags={["playwithlights"]} url="https://playwithlights.com">
                        <Box m={1}><TwitterIcon size={32} round={true} /></Box>
                    </TwitterShareButton>
                </p>
                <Button className={classes.fullWidthButton} variant="contained" onClick={() => n("/play")} color="primary">
                        Play some more!
                </Button>
                <p>
                        If something wasn't working or seemed off please let me know!  <Link href="mailto:david@playwithlights.com">david@playwithlights.com</Link>
                </p>

            </Box>
        </LogoBox>
    );
    
}

export default withStyles(useStyles, { withTheme: true })(Thanks);
