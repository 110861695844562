import {  Button, ButtonGroup, Theme } from "@mui/material";
import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import { serverSceneDefinition } from "sharedTypes/serverInteractions";

const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // backgroundColor: theme.palette.grey[500],
        },
    });

interface Props extends WithStyles<typeof useStyles>{ 
    presets: Array<serverSceneDefinition>
    onPresetSelect: (preset:string)=>void
}

class PresetSelector extends React.Component<Props> {
    state = {
        selected: this.props.presets?.length>0 ?this.props.presets[0].index.toString(): "0",
    };

    constructor(p:Props){
        super(p);
        this.props.onPresetSelect(this.state.selected);
    }

    onPreset = (p:number)=>{
        this.setState({selected:p.toString()}, ()=>{
            this.props.onPresetSelect(p.toString());
        });
    };


    render(){
        const {classes} = this.props;
        const {selected} = this.state;

        const presets = [];
        for(const id of this.props.presets){
            presets.push(<Button key={id.index} onClick={(_e: React.MouseEvent)=>this.onPreset(id.index)} variant={selected===id.index.toString()?"contained":"outlined"}>{id.name}</Button>);
        }

        return (
            <ButtonGroup disableElevation className={classes.root}  orientation="vertical" aria-label="outlined primary button group">
                {presets}
            </ButtonGroup>
        );
    }   
}

export default withStyles(useStyles, { withTheme: true })(PresetSelector);
