type callbacks = {
    onChange: (pos: GeolocationPosition) => void;
    onError: (err: string | GeolocationPositionError) => void;
};

class LocationManager {
    watcher: number | null = null;
    callbacks: callbacks;

    constructor(callbacks: callbacks) {
        this.callbacks = callbacks;
        this.connect();
    }

    connect = () => {
        const { callbacks } = this;
        if (navigator.geolocation) {
            this.watcher = navigator.geolocation.watchPosition(
                this.onChange,
                this.onError,
                { enableHighAccuracy: true, timeout: 30000 }
            );
            navigator.geolocation.getCurrentPosition(this.onChange, this.onError);
        } else {
            callbacks.onError("Your device doesn't support geolocation");
        }
    };

    close = () => {
        if (this.watcher != null) {
            navigator.geolocation.clearWatch(this.watcher);
        }
    };

    onChange = (pos: GeolocationPosition) => {
        this.callbacks.onChange(pos);
    };

    onError = (err: GeolocationPositionError) => {
        console.log("on err", err);
        if (err.code === err.TIMEOUT) {
            this.reconnect();
        } else {
            this.callbacks.onError(err);
        }
    };

    reconnect = () => {
        if (this.watcher != null) {
            navigator.geolocation.clearWatch(this.watcher);

            setTimeout(
                () => {
                    return this.connect();
                },
                // alert("trying for updates again")
                1000
            );
        }
    };
}

export default LocationManager;

export function canLocate(): boolean {
    return navigator.geolocation !== undefined;
}
