import { modeStates } from "./AppState";
import { effectStates } from "./EffectSelector";

class Server {
    connectionString: string;
    events: any;
    firstConnect: boolean;
    lastOrientationSent: number | null;
    _dirCalibration: number;
    _lastOrentationData: {};
    _socket?: WebSocket;
    id: number;

    constructor(host: any, port: any, events: any) {
        // this.connect = this.connect.bind(this);
        // this.unload = this.unload.bind(this);
        // this.connected = this.connected.bind(this);
        // this.close = this.close.bind(this);
        this.connectionString = `wss://${host}`;
        this.events = events;
        this.firstConnect = false;
        this.lastOrientationSent = null;
        this._dirCalibration = 0;
        this._lastOrentationData = {};
        this.id = Math.random();
        this.connect();
    }

    connect = () => {
        if ([0, 1].includes(this._socket?.readyState as number)) {
            return;
        }
        // console.log("ACTUALLY CONNECTING CALLED FOR SERVER", this.id);

        this._socket = new WebSocket(this.connectionString);
        this._socket.onclose = this.close;
        this._socket.onopen = this.connected;
        this._socket.onmessage = this.events.message;
        return this.events.connecting();
    };

    unload = () => {
        if (this._socket) {
            this._socket.onclose = null;
            this._socket.close();
        }
    };

    connected = () => {
        // console.log("connected CALLED FOR SERVER", this.id);

        this.events.connect();
        if (!this.firstConnect) {
            return (this.firstConnect = true);
        } else {
            return this.events.reconnect();
        }
    };

    close = () => {
        this.events.disconnect();
        return setTimeout(this.connect, 1000);
    };

    _send = (message: {
        ping?: boolean;
        colors?: Array<string>;
        effect?: string;
        location?: [lat: number, lon: number, accuracy: number];
        locationID?: string;
        scene?: string;
        mode?: string;
        tiltLR?: number;
        tiltFB?: number;
        dir?: number;
        cue?: boolean;
        paint?: boolean;
        paintColor?: string;
        id?: string;
        notification?: any;
        sprite?: string;
        overrideKey?: string;
    }) => {
        // console.log "Sending message", message
        if (this._socket) {
            this._socket.send(JSON.stringify(message));
        }
    };

    //   calibrateDirection(){
    //     if (this._lastOrentationData.dir < 180) {
    //       return this._dirCalibration = this._lastOrentationData.dir * -1;
    //     } else {
    //       return this._dirCalibration = 360 - this._lastOrentationData.dir;
    //     }
    //   }

    sendEverything = ({
        colors,
        effect,
        location,
        scene,
        mode,
    }: {
        colors: Array<string>;
        effect: effectStates;
        location?: GeolocationPosition;
        scene: string;
        mode: modeStates;
    }) => {
        let processedLocation;
        if (location) {
            processedLocation = [
                location.coords.latitude,
                location.coords.longitude,
                location.coords.accuracy,
            ] as [lat: number, lon: number, accuracy: number];

            if (process.env.NODE_ENV != "production") {
                processedLocation[0] = 47.7669591;
                processedLocation[1] = -122.1958623;
                processedLocation[2] = 1;
            }

        }

        return this._send({
            ping: true,
            colors,
            effect,
            location: processedLocation,
            scene,
            mode,
        });
    };

    sendOrientation = (tiltLR: number, tiltFB: number, dir: number) => {
        this._lastOrentationData = { tiltLR, tiltFB, dir };
        if (
            Date.now() - 30 >
            (this.lastOrientationSent ? this.lastOrientationSent : 0)
        ) {

            const computed = (dir + this._dirCalibration) % 360;

            if (computed > 45 && computed < 180) {
                this._dirCalibration--;
            } else if (computed < 315 && computed > 180) {
                this._dirCalibration++;
            }

            this._send({ tiltLR, tiltFB, dir: (dir + this._dirCalibration) % 360 });
            this.lastOrientationSent = Date.now();
        }
    };

    cue = () => {
        return this._send({ cue: true });
    };

    paintOn = (color: any) => {
        return this._send({ paint: true, paintColor: color });
    };

    paintOff = () => {
        return this._send({ paint: false });
    };

    sendId = (id: any, locationID?: string) => {
        return this._send({ id, locationID });
    };

    ping = () => {
        return this._send({ping: true});
    };

    notify = (name: any) => {
        return this._send({ notification: name });
    };

    sendSprite = (sprite: any) => {
        return this._send({ sprite });
    };

    setMode = (mode: any) => {
        return this._send({ mode });
    };

    setScene = (scene: any) => {
        return this._send({ scene });
    };

    setEffect = (name: any, params?: any) => {
        return this._send({ effect: name });
    };

    setColors = (colors: any) => {
        return this._send({ colors });
    };

    setLocation = (lat: any, lon: any, heading: any) => {
        
        if (process.env.NODE_ENV != "production") {
            lat = 47.7669591;
            lon = -122.1958623;
        }

        return this._send({ location: [lat, lon, heading] });
    };

    setLocationID = (locationID: string) => {
        return this._send({ locationID: locationID });
    };

    setOverrideKey = (overrideKey: string) => {
        return this._send({ overrideKey });
    };
}

export default Server;
