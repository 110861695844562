import {
    Box,
    Button,
    Theme,
} from "@mui/material";

import { createStyles, WithStyles, withStyles } from "@mui/styles";

import React from "react";
import {  useNavigate } from "react-router-dom";

import LogoBox from "../prims/LogoBox";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {
        },

        fullWidthButton: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
    });
};

interface Props extends WithStyles<typeof useStyles> {
    onSeenLocationPrompt: (_e: React.MouseEvent) => void;
}

function LocationPrompt(props: Props){
    const { classes } = props;
    const n = useNavigate();

    gtag("event", "info", {
        "event_category": "static",
        "event_label": "Info page"
    });

    return (
        <LogoBox>

            <Box>
                <h1>Can we use your location?</h1>

                <Box>
                        Pretty please with sugarplums on top? To find the light display
                        you{"'"}re closest to, we need to use your location.
                </Box>

                <Button
                    className={classes.fullWidthButton}
                    variant="contained"
                    onClick={(e: React.MouseEvent) => {
                        props.onSeenLocationPrompt(e);
                        n("/play");
                    }}
                >
                        Sure, use my location, <br />I'm no grinch!
                </Button>
            </Box>
        </LogoBox>
    );
    
}

export default withStyles(useStyles, { withTheme: true })(LocationPrompt);
