import {
    Box,
    Theme,
} from "@mui/material";

import { createStyles, WithStyles, withStyles } from "@mui/styles";

import React from "react";
import Logo from "../pwl_color.svg";
import {NavLink} from "react-router-dom";

const useStyles = (theme: Theme) =>
    createStyles({
        root: {

        },

    });

interface Props extends WithStyles<typeof useStyles>{ 
    children?: React.ReactNode;
}

class LogoBox extends React.Component<Props> {
    render(){
        const {children} = this.props;
        return (
            <Box m={4}>                
                <NavLink to="/">
                    <img
                        style={{
                            marginTop: "0px",
                            marginBottom: "15px",
                            width: "100%",}}
                        color="white"
                        src={Logo}
                        alt="PlayWithLights.com"
                    />
                </NavLink>
                {children}
            </Box>
        );
    }
}


export default withStyles(useStyles, { withTheme: true })(LogoBox);
