import {
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Theme,
} from "@mui/material";
import React, { useState } from "react";
import { canLocate } from "./locationManager";
import { serverLocation, serverMessage } from "sharedTypes/serverInteractions";
import { hoursToTimeString } from "./lib/time";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import LogoBox from "./prims/LogoBox";
import { NavigateFunction, useNavigate } from "react-router";

const useStyles = (theme: Theme) =>
    createStyles({
        root: {},
        fullWidthButton: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        centeredContent: {
            marginTop: "16px",
            marginBottom: "16px",
            textAlign: "center",
        },
    });

interface Props extends WithStyles<typeof useStyles> {
    serverLocation: serverLocation | undefined;
    serverState: serverMessage;
    reCue: () => void;
    setupServer: () => void;
}

function Welcome(props: Props){
    const n = useNavigate();

    const { classes, serverState, serverLocation, reCue } = props;

    const completed = [false, false, false];
    const errored = [false, false, false];
    let activeStep = 0;

    let prompt = (
        <Box>No Content</Box>
    );

    if (!canLocate()) {
        errored[0] = true;

        gtag("event", "device_location_not_can_location", {
            event_category: "location",
            event_label: "Location Api not available",
        });

        prompt = (
            <Box m={2}>
                <h1>Your device doesn't support location services :(</h1>
                    Either your device doesn't support location, or you've disabled it.
            </Box>
        );
    } else {
        activeStep = 1;
        completed[0] = true;

        if (serverState?.locationState === "valid") {
            activeStep = 2;
            completed[1] = true;


            if (serverState.active) {
                completed[3] = true;
                activeStep = 3;
                prompt = <Box m={2}>Connected to {serverLocation?.name}</Box>;
            } else if (!serverLocation?.time) {
                completed[1] = true;
                activeStep = 2;

                const timeString = hoursToTimeString(serverLocation?.hours);


                gtag("event", "outside_time", {
                    event_category: "time",
                    event_label: "Outside Time Window",
                });

                prompt = (
                    <Box>
                        <h2>The lights are off right now. :(</h2>
                            You're close to the display at <b>{serverLocation?.name}</b>. The lights are off,
                            but know that we deeply respect the night owl in you.
                        <Box className={classes.centeredContent}>
                                The schedule is {timeString}.
                        </Box>
                        <Button
                            className={classes.fullWidthButton}
                            variant="contained"
                            onClick={() => n("/locations")}
                        >
Find Other Displays
                        </Button>
                    </Box>
                );
            } else if (serverState.position === -2) {
                gtag("event", "still_there", {
                    event_category: "active",
                    event_label: "Still there?",
                });

                completed[1] = true;
                activeStep = 2;

                prompt = (
                    <Box>
                        <h2>Ya still there?</h2>
                            We haven't noticed any of your amazing designs lighting up the
                            night with glory and wonder for awhile.
                        <Button
                            className={classes.fullWidthButton}
                            variant="contained"
                            onClick={reCue}
                            style={{ marginTop: "24px" }}
                        >
                                I'm here! I'm here!
                        </Button>
                    </Box>
                );
            } else if (
                serverState.position !== undefined &&
                    serverState.position > 0
            ) {
                gtag("event", "in_line", {
                    event_category: "active",
                    event_label: "In line",
                });

                prompt = (
                    <Box className={classes.centeredContent}>
                        <h2>
                                Best news ever! You're in line, at position{" "}
                            {serverState.position}. Everyone gets{" "}
                            {serverLocation.controlTimes?.limitName}, so get those twinkle
                                fingers ready to go!
                        </h2>
                        <CircularProgress color="secondary" />
                    </Box>
                );
            } else if (serverState.position === -1) {
                gtag("event", "out_of_time", {
                    event_category: "active",
                    event_label: "Someone elses turn",
                });

                prompt = (
                    <Box>
                        <h1>
                                It's someone else's turn now... But we really liked your designs!)
                        </h1>
                        <p>
                                Someone else is in line behind you, and it's now their turn. If
                                you'd like to play again, you can get back in line!
                        </p>

                        <Button
                            className={classes.fullWidthButton}
                            variant="contained"
                            onClick={reCue}
                        >
                                Get back in line
                        </Button>
                    </Box>
                );
            } else {
                prompt = (
                    <Box>
                        <CircularProgress color="primary" />
                    </Box>
                );
            }
        } else if (serverState?.locationState === "tooFar") {
            gtag("event", "location_tooFar", {
                event_category: "location",
                event_label: "Too far away",
            });

            completed[0] = true;
            errored[1] = true;
            activeStep = 1;


            prompt = (
                <Box>
                    <h2>No Controllable Lights Nearby</h2>
                        Looks like you're too far away to control any lights from your
                        current location. This could be a temporary outage with a specific
                        location.

                    <Button
                        className={classes.fullWidthButton}
                        variant="contained"
                        onClick={() => n("/locations")}
                        style={{ marginTop: "24px" }}
                    >
                            Find other displays
                    </Button>

                </Box>
            );
        } else {
            gtag("event", "location_pending", {
                event_category: "location",
                event_label: "Waiting for location data",
            });
            prompt = (
                <Box alignContent="center">
                    <h2>Connecting</h2>
                        We're waiting for your device to send us location data.... Sometimes this can take a minute, and sometimes you just need to refresh.
                    <Box marginTop="24px">
                        <LinearProgress color="secondary" />
                    </Box>
                </Box>
            );
        }
    }

    return (
        <LogoBox>
            {prompt}
        </LogoBox>
    );
    
}

export default withStyles(useStyles, { withTheme: true })(Welcome);
