import { Box, Button, ButtonGroup } from "@mui/material";
import { FlashOn, CheckBoxOutlineBlank, Flare, DoubleArrow } from "@mui/icons-material";
import React from "react";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//     button: {
//       margin: theme.spacing(1),
//     },
//   }));


const useStyles = () =>
    createStyles({
        root:{},
    });



export type effectStates = "solid"|"twinkle"|"sparkle"|"ordered"
// export type effectStates = "twinkle"

type effectState = {
    selected:effectStates;
}

interface Props extends WithStyles<typeof useStyles>{
    effect: effectStates;
    onSelectEffect: (effect: effectStates)=>void;
}

class EffectSelector extends React.Component<Props> {
    state = {
        selected: this.props.effect
    } as effectState;
    

    selectEffect = (effect: effectState["selected"]) => {
        this.setState({selected: effect});
        this.props.onSelectEffect(effect);
    };

    render(){

        return (
            <Box p={2} style={{paddingTop:"4px"}} display="flex" justifyContent="center">
                <ButtonGroup color="neutral" style={{paddingTop:"0px"}} aria-label="outlined primary button group" disableElevation>
                    <Button id="solid" onClick={(_e: React.MouseEvent)=>this.selectEffect("solid")} {...{variant: this.state.selected === "solid" ? "contained":"outlined"}} startIcon={<CheckBoxOutlineBlank />}>Solid</Button>
                    <Button id="twinkle" onClick={(_e: React.MouseEvent)=>this.selectEffect("twinkle")} {...{variant: this.state.selected === "twinkle" ? "contained":"outlined"}}  startIcon={<Flare />}>Twinkle</Button>
                    <Button id="sparkle" onClick={(_e: React.MouseEvent)=>this.selectEffect("sparkle")} {...{variant: this.state.selected === "sparkle" ? "contained":"outlined"}} startIcon={<FlashOn />}>Sparkle</Button>
                    {/* <Button id="ordered" onClick={(_e: React.MouseEvent)=>this.selectEffect("ordered")} {...{variant: this.state.selected === "ordered" ? "contained":"outlined"}} startIcon={<DoubleArrow />}>Ordered</Button> */}
                </ButtonGroup>
            </Box>
        );
    }   
}

export default withStyles(useStyles, { withTheme: true })(EffectSelector);
