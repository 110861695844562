import {
    Box,
    Button,
    Link,
    Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";
import LogoBox from "../prims/LogoBox";
import {  useNavigate } from "react-router-dom";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {
            textShadow: "0 0 2px rgba(0,0,0,.3)"

        },

        fullWidthButton: {
            marginTop: "20px !important",
            width: "100%",
        },

        link: {
            color: theme.palette.primary.light,
            textDecoration: "none",
            cursor: "pointer",
            // textShadow: "0 0 1px #ffffff",
        },

    });
};

interface Props extends WithStyles<typeof useStyles> {
}

function Info(props: Props) {
    const { classes } = props;


    gtag("event", "info", {
        "event_category": "static",
        "event_label": "Info page"
    });

    const n = useNavigate();
    return (
        <LogoBox>
            <Box>
                    Play with nearby lights from your mobile phone, set the colors, effects or even paint with light! Lookup nearby <RouterLink className={classes.link} to="/locations/">displays</RouterLink> to find a venue near you!
            </Box>

            <Box>
                <Button className={classes.fullWidthButton} variant="contained" onClick={() => n("/play")} color="neutral">
                        Play with the lights!
                </Button>
            </Box>
            <Box style={{ marginTop: "30px" }}>
                <video src="./pwl-2022.mp4" width="100%" loop autoPlay playsInline muted controls={false} />
            </Box>
            <Box style={{ marginTop: "20px" }}>
                <h2>Interested in an interactive display for your venue?</h2>
                <p>
                        We would love to work with you. Just send an email to{" "}
                    <Link className={classes.link} href="mailto:david@playwithlights.com">david@playwithlights.com</Link>
                        . And please let us know about your venue and the project you're working on.
                </p>
                <p>
                    <FacebookShareButton quote="I just played with some interactive Christmas Lights!  You should too!" hashtag="#playwithlights" url="https://playwithlights.com">
                        <Box m={1}><FacebookIcon size={32} round={true} /></Box>
                    </FacebookShareButton>
                    <TwitterShareButton title="I just played with some interactive Christmas Lights!  You should too!" hashtags={["playwithlights"]} url="https://playwithlights.com">
                        <Box m={1}><TwitterIcon size={32} round={true} /></Box>
                    </TwitterShareButton>
                </p>
            </Box>
        </LogoBox>
    );
}

export default withStyles(useStyles, { withTheme: true })(Info);
