import {
    Theme,
} from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {
        },

        fullWidthButton: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
    });
};

interface Props extends WithStyles<typeof useStyles>, React.PropsWithChildren {
    locationError?: string | GeolocationPositionError;
    hasSeenLocationPrompt: boolean;
}

class LocationAccessRequired extends React.PureComponent<Props> {

    // shouldComponentUpdate = (newProps: Props): boolean =>{
    //     return this.props != newProps;
    // }

    render() {

        if (!this.props.hasSeenLocationPrompt) {
            return (
                <Navigate to="/locationPermission" />
            );
        }
        if (this.props.locationError) {
            return (
                <Navigate to="/locationError" />
            );
        }


        return this.props.children;
    }
}

export default withStyles(useStyles, { withTheme: true })(LocationAccessRequired);
