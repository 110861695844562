import {
    Box,
    Theme,
} from "@mui/material";
import React from "react";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import {  useNavigate } from "react-router-dom";

import LogoBox from "../prims/LogoBox";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {
        },

        fullWidthButton: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
    });
};

interface Props extends WithStyles<typeof useStyles> {
    locationError?: string | GeolocationPositionError;
}

function LocationError(props: Props) {
    const { locationError } = props;

    gtag("event", "info", {
        "event_category": "static",
        "event_label": "Info page"
    });

    let prompt;

    if (locationError) {
        if (typeof locationError === "string") {
            gtag("event", "location_error_" + locationError, {
                event_category: "location",
                event_label: locationError,
            });

            prompt = <Box>Location Error {locationError}</Box>;
        } else {
            const pError = locationError as GeolocationPositionError;
            if (pError.code === pError.PERMISSION_DENIED) {
                gtag("event", "location_error_denied", {
                    event_category: "location",
                    event_label: "Location permission denied",
                });

                prompt = (
                    <Box>
                        <h1>Location Services Unavailable</h1>
                        <p>
                                Make sure you have location services enabled or gps turned on,
                                or refresh and click "allow". You've got this!
                        </p>
                        <p>
                                On an iPhone, location services must be turned on for Safari
                                (Settings, Privacy, Location Services, Safari Websites).{" "}
                        </p>
                    </Box>
                );
            } else if (pError.code === pError.POSITION_UNAVAILABLE) {
                gtag("event", "location_error_unavailable", {
                    event_category: "location",
                    event_label: "Location permission unavailable",
                });

                prompt = (
                    <Box>
                        <h1>We can't find you!</h1>
                        <p>
                                Without knowing where you are, we don't know which lights you
                                are trying to control. If you really do want the gift that keeps
                                on giving, enable location services, and gift this page a refresh!
                        </p>
                    </Box>
                );
            }
        }
    } else {
        useNavigate()(-1);
    }

    return (
        <LogoBox>
            {prompt}
        </LogoBox>
    );
    
}

export default withStyles(useStyles, { withTheme: true })(LocationError);