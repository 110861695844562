import {
    Box,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Link,
    Theme,
    Typography,
} from "@mui/material";
import React from "react";
import BulbOn from "../bulbOn.svg";
import BulbOff from "../bulbOff.svg";

import { hoursToTimeString } from "../lib/time";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import LogoBox from "../prims/LogoBox";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {
        },

        fullWidthButton: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        listItemAvatar: {
            minWidth: "34px",
        },
        inline: {
            display: "inline",
        },
        bulbIndicator: {
            height: "20px",
            marginRight: "10px",
            float: "left",
        },

    });
};

interface Props extends WithStyles<typeof useStyles> {
    geoLocation: GeolocationPosition;
}

type PlayWithLightsLocation = {
    _id: string,
    dis: number,
    lastSeen: number;
    name: string;
    address: string;
    online: boolean;
    times: { start: number; stop: number };
    turnedOn: boolean;
}

class Locations extends React.Component<Props> {
    state = {
        locations: [],
        receivedResults: false,
    } as {
        locations: Array<PlayWithLightsLocation>;
        receivedResults: boolean;
    };

    componentDidMount = () => {
        this.getNearbyLocations();
        if (this.refreshInterval == undefined) {
            this.refreshInterval = setInterval(this.getNearbyLocations, 60000);
        }
    };

    componentWillUnmount = () => {
        if (this.refreshInterval != undefined) {
            clearInterval(this.refreshInterval);
        }
    };

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.geoLocation != this.props.geoLocation) {
            this.getNearbyLocations();
        }
    };

    updateLocations = (locs: Array<PlayWithLightsLocation>) => {
        this.setState({ locations: locs, receivedResults: true });
    };

    getNearbyLocations = () => {
        const updater = this.updateLocations;

        if (this.props.geoLocation) {
            const httpReq = new XMLHttpRequest();
            httpReq.onreadystatechange = function () { // Call a function when the state changes.
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    updater(JSON.parse(this.responseText) as Array<PlayWithLightsLocation>);
                }
            };
            const { latitude, longitude } = this.props.geoLocation.coords;
            httpReq.open("GET", "https://" + (process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : "connector.playwithlights.com") + "/locations?lat=" + latitude + "&lon=" + longitude, true);
            httpReq.send();
        }
    };
    refreshInterval: NodeJS.Timeout | undefined;

    render() {

        const { classes } = this.props;

        gtag("event", "info", {
            "event_category": "static",
            "event_label": "Info page"
        });

        const locations: Array<JSX.Element> = [];

        for (const loc of this.state.locations) {

            const actuallyOnRightNow = loc.turnedOn && loc.online;

            const link = "https://www.google.com/maps/dir//" + encodeURIComponent(loc.address);

            locations.push(
                <ListItem key={loc._id}>

                    <ListItemText
                        primary={
                            <React.Fragment>
                                <img src={actuallyOnRightNow ? BulbOn : BulbOff} className={classes.bulbIndicator} />
                                {loc.name} {!loc.online? "- Currently Disconnected":""}

                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                </Typography>
                                {loc.dis.toFixed(2) + " miles - " + hoursToTimeString(loc.times)}
                                <Link style={{ display: "box", float: "right" }} href={link} color="primary">Directions</Link>
                            </React.Fragment>
                        }
                    />

                </ListItem>);
        }

        return (

            <LogoBox>

                {this.state.receivedResults ? (
                    <Box>
                        {this.state.locations.length > 0 ? (
                            <Box>
                                <h1 style={{ marginBottom: "0px" }}>Nearest Displays</h1>
                                <List disablePadding={true} dense >
                                    {locations}
                                </List>
                            </Box>
                        ) :
                            <Box marginTop="24px">
                                <h1 style={{ marginBottom: "0px" }}>No Online Displays Found</h1>
                                No displays are currently online.  Please check back again later.
                            </Box>}
                    </Box>
                ) : <Box style={{ marginTop: "24px" }}>
                    We're waiting for your device to send us location data.... You can
                    try refreshing if nothing happens, or just to make yourself feel
                    better.
                    <Box marginTop="24px">
                        <LinearProgress color="secondary" />
                    </Box>
                </Box>
                }


            </LogoBox>
        );
    }
}



export default withStyles(useStyles, { withTheme: true })(Locations);
