import { AppBar, Box, Dialog, IconButton, List, ListItem, ListItemText, Theme, Toolbar, Typography } from "@mui/material";
import React from "react";
import serverInteractions from "sharedTypes/serverInteractions";
import { CloseOutlined, Wifi } from "@mui/icons-material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";


const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            // bottom: 0,
            // top:"auto",
            // position: "fixed",
            // width: "100%",
        },

        locationPicker: {
            backgroundColor: theme.palette.background.default + " !important",
        },

        currentLocation: {
            color: "#fff",
            width: "100%",
            // margin: "5px",
        }
    });

interface Props extends WithStyles<typeof useStyles> {
    locations: serverInteractions.serverMessage["locations"]
    location?: string
    onSelectLocation: (location: string) => void
    serverPing?: ()=> void
}

class LocationSelector extends React.Component<Props> {
    state = {
        locationPicker: false,
    };

    constructor(p: Props) {
        super(p);
        // this.props.onPresetSelect(this.state.selected);
    }

    locationPickerOpen = () => {
        if(this.props.serverPing)this.props.serverPing();
        this.setState({ locationPicker: true });
    };

    locationPickerClose = () => {
        if(this.props.serverPing)this.props.serverPing();
        this.setState({ locationPicker: false });
    };

    selectLocation = (locationID: string) => {
        this.props.onSelectLocation(locationID);
        this.locationPickerClose();
    };

    render() {
        const { location, locations, classes } = this.props;
        const { locationPicker } = this.state;

        if (!locations || location && !locations[location]) {
            return (<Box>Location Invalid</Box>);
        }

        const currentLocation = locations[location!];


        const presets = [];
        for (const id in locations) {
            const l = locations[id];

            let primary: any = l.name;
            if (id == location) {
                primary = <Typography><Wifi sx={{ display: "inline-flex", margin: "5px", verticalAlign: "middle" }} color="secondary" /> <b>{l.name}</b></Typography>;
            }

            presets.push(<ListItem key={id} onClick={() => this.selectLocation(id)} button>
                <ListItemText primary={primary} secondary={l.address} /></ListItem>);
        }

        return (
            <Box className={classes.root}>
                <Box className={classes.currentLocation} onClick={this.locationPickerOpen}>

                    {/* <IosShare sx={{ display: "inline-flex", margin: "5px", verticalAlign: "middle" }} color="secondary" /> */}

                    {Object.keys(locations).length > 1 ?
                        <Typography sx={{textAlign: "center"}}>
                            {/* <Menu sx={{ display: "inline-flex", margin: "5px", verticalAlign: "middle" }} color="secondary" /> */}
                            Connnected to: <b>{currentLocation.name}</b><br/>
                            <Typography variant="body2" style={{textDecoration: "underline"}}>multiple locations available</Typography>

                        </Typography>
                        : undefined}
                    {/* <IosShare sx={{display:"inline-flex", margin: "5px", verticalAlign:"middle"}} color="secondary"/> */}
                </Box>
                <Dialog
                    fullScreen
                    open={locationPicker}
                    onClose={this.locationPickerClose}
                    className={classes.locationPicker}
                >
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.locationPickerClose}
                                aria-label="close"
                            >
                                <CloseOutlined />
                            </IconButton>
                            <Typography style={{}} variant="h6" component="div">Nearby Locations</Typography>

                        </Toolbar>
                    </AppBar>
                    <List>
                        {presets}
                    </List>
                </Dialog>
            </Box>
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(LocationSelector);
